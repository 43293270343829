
import MainLayout from "../Common/Layouts/MainLayout/MainLayout";
// const Login = lazy(() => import("pages/Public/Login/Login"));
import Dashboard from "../Pages/Private/Dashboard/Dashboard";
import Claim from "../Pages/Private/Claim/Claim";
import NotFound from "../Pages/Public/NotFound/NotFound";

const globalRoutes = [
    {
        index: true,
        element: <Dashboard />,
    },
    {
        path: "claim",
        element: <Claim />,
    },

];
export const routes = [
   
    {
        path: "/",
        element: <MainLayout isPrivate />,
        errorElement: <NotFound />,
        children: globalRoutes,
    },
];
