import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CommonButton from "../../../Common/CommonButton/CommonButton";
import "./History.scss";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { callContractSendMethod } from "../../../../services/contract.action";

const Claim = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { userWalletAddress } = useSelector((state) => state.userDataSlice);
  console.log("userWalletAddress", userWalletAddress);

  const handleClaimYield = async () => {
    if (!userWalletAddress) {
      toast.error("Wallet is not connected.");
      return;
    }
    try {
      // setIsLoading(true);

      let result = await dispatch(
        callContractSendMethod("claimYield", [], userWalletAddress, 0)
      );
      console.log("result", result);

      if (result?.transactionHash) {
        toast.success("Deposit successful!");
      }
    } catch (error) {
      // console.log("error", error);
      // toast.error("Transaction failed.",error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div className="history_page">
      <Row className="pb-5 align-items-center">
        <Col xs={5}>
          <h1>CLAIM</h1>
        </Col>
        <Col className="text-end">
          <CommonButton
            title="Claim Yield"
            onClick={handleClaimYield}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Claim;
